import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import SportsBar from '../components/SportsBar'
import FightList from '../components/FightList'
import FighterList from '../components/FighterList'
import { Modal, Loading, Input, Button, Spacer } from '@geist-ui/react'
import { DollarSign, ThumbsUp, ThumbsDown } from '@geist-ui/react-icons'
import queryString from 'query-string'


const Index = () => {

  const [password, setPassword] = useState()
  const [name, setName] = useState()

  useEffect(() => {
    if(password === 'fp') {
      fetch(`https://hooks.zapier.com/hooks/catch/9332947/bj60m6l/`, {
        body: JSON.stringify({
          name: name
        }),
        method: 'POST'
      }).then(response => {
        return response.json().then(response => console.log(response))})
        .catch(error => console.log(error))
    }
  }, [password])


  const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

  return (
    <>
     <Helmet>
      <title>Fanpowered Fights - Vote for the Fights You Want to See.</title>
      <meta name="description" content="We're on a mission to put the power back in the hands of the fans. Vote on the boxing fights you want to see." />
     </Helmet>
     <Modal visible={password !== 'fp'} width="90rem" disableBackdropClick={true}>
        <Input width="100%" size="large" value={name} placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
        <Spacer y={.5} />
        <Input width="100%" size="large" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value.toLowerCase())} />
        <Spacer y={1.5} />
      </Modal>
     <SportsBar sport="all" />
     <Spacer h={6.5} />
     <FightList short="true" home="true" path="/" login={parsed.login} />
     <div id="about" className="about">
       <h4>🫡 How it Works</h4>
      <div className="step-item">
         <div className="step">
           <div className="big-emoji">👍</div>
           <h5>Upvote Fights You Want to See</h5>
         </div>
         <div className="item">
           <p>Vote for the fights that you want to see. Make your prediction and pledge your PPV amount.</p>
           <Button scale={.5} type="secondary" onClick={() => navigate('/fights/')} ghost>
           View Fights</Button>
         </div>
       </div>
       <div className="step-item">
         <div className="step">
           <div className="big-emoji">💰</div>
           <h5>Pledge Towards the PPV</h5>
         </div>
         <div className="item">
           <p>Let us know how much you would spend to watch the fight. No real money exchanges hands - 
           we just calculate the total amount fans are willing to pay to make this happen.</p>
           <Button scale={.5} type="secondary" onClick={() => navigate('/fights/')} ghost>
           View Fights</Button>
         </div>
       </div>
       <div className="step-item">
         <div className="step">
           <div className="big-emoji">💬</div>
           <h5>Talk to Other Fight Fans About the Matchup</h5>
         </div>
         <div className="item">
           <p>Let everyone know in the comments who you think will win, who you want to win, and why.</p>
           <Button scale={.5} type="secondary" onClick={() => navigate('/fights/')} ghost>
            View Fights</Button>
         </div>
       </div>
     </div>
     <h4 className="homepage">🏆 Athletes</h4>
     <FighterList home="true" />
    </>
  )
}

export default Index
