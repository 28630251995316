import React, { useState, useEffect } from "react"
import { navigate } from 'gatsby'
import { Loading } from '@geist-ui/react'


export default function FighterCard (props) {

  const [loading, setLoading] = useState(true)
  const [fighter, setFighter] = useState({})

  useEffect(() => {
    if (props.fighter) {
      let fighterread = "fighters" + "-" + props.fighter;
      fetch(`/.netlify/functions/fauna-read/${fighterread}`).then(response => {
          return response.json().then(response => (setFighter(response.data), setLoading(false)))
          .catch(error => setLoading(false))
      });
    }
  }, [props.fighter]);


  return (<>
    <div className="fighter-card" onClick={() => navigate('/fighter/' + fighter.name.toLowerCase().split(" ").join("-") + '/')}>
            {loading && <Loading space={2.5} />}
             <img src={fighter.photo} alt={fighter.name} />
             <h6>{fighter.name}</h6>
             <div className="flex">
               <div className="chip">
                {fighter.country}
               </div>
               <div className="chip sport">
                <span className="sports-font">{props.icon}</span> {fighter.sport}
               </div>
             </div>
           </div>
  </>)
}