import React, { useState, useEffect, useContext } from "react"
import { navigate } from 'gatsby'
import UserContext from './context/UserContext'
import { Loading } from '@geist-ui/react'


export default function SportsBar (props) {

  const { allsports } = useContext(UserContext)

  useEffect(() => {
      if(props.sport) {
         let sportindex = allsports.indexOf(allsports.find((sport) => sport[0] === props.sport))
         if(sportindex > 4) {
             document.getElementById("sports-bar").scrollLeft += 60 * sportindex;
         }
      }
  }, [allsports, props.sport])

	return (
    <>
     <div className="sports-bar" id="sports-bar">
     	{allsports.map((sport, index) => (
     		<div className={props.sport === sport[0] ? "selected sport-item" : "sport-item"} onClick={() => navigate('/sport/' + sport[0].toLowerCase().split(" ").join("-"))}>
     			<h3>{sport[1]}</h3>
     			<p>{sport[0]}</p>
     		</div>
     	))}
     </div>
  	</>
 )
}