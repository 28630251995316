import React, { useState, useEffect } from "react"
import FighterCard from '../components/FighterCard'
import { Link, navigate } from "gatsby"
import { Loading, Grid, Input, Spacer, Button, Select } from '@geist-ui/react'
import { Search, ChevronRight } from '@geist-ui/react-icons'

const FightersPage = (props) => {

  const [fighters, setFighters] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState()
  const [sports, setSports] = useState([])
  const [sport, setSport] = useState()
  const [filteredfighters, setFilteredFighters] = useState([])


  useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'fighters_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setFighters(response.data || []), setLoading()))
         .catch(error => setLoading());
  }, [])

   useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'sports_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setSports(response.data || []), setLoading()))
         .catch(error => setLoading());
  }, [])

  useEffect(() => {
    if(search || sport) {
      setFilteredFighters(fighters.filter((fighter) => search ? fighter[0].toLowerCase().includes(search.toLowerCase()) : true)
        .filter((fighter) => sport ? sport === fighter[3] : true)
        .slice(0, props.home ? 8 : fighters.length));
    }
    else {
      setFilteredFighters(fighters);
    }
  }, [fighters, search, sport])




  return (
      <div id="fighters" className={props.home ? "fighters-page home" : "fighters-page"}>
        <Spacer y={1} />
         {!props.home && <Grid.Container gap={1}>
           <Grid xs={24} sm={12}>
             <Input scale={1} width="100%" placeholder="Search" clearable icon={<Search />}
             onChange={(e) => setSearch(e.target.value)} />
           </Grid>
           <Grid xs={24} sm={12}>
            <Select scale={1} width="100%" placeholder="Choose Sport" 
               clearable onChange={(val) => setSport(val)}>
              <Select.Option value=""><span className="sports-font">%</span> Show All</Select.Option>
              <Select.Option value="Celebrity"><span className="sports-font">H</span> Celebrity</Select.Option>
              {sports.map((sport) => (
                <Select.Option value={sport[0]}><span className="sports-font">{sport[1]}</span> {sport[0]}</Select.Option>
               ))}
            </Select>
           </Grid>
         </Grid.Container>}
         {loading && <><Spacer y={4} /><Loading spaceRatio={2.5} /></>}
         {!loading && <>
           <Grid.Container gap={1.5} className="fighter-grid">{filteredfighters.map((fighter) => (
           <Grid xs={24} sm={props.home ? 24 : 12} md={props.home ? 12 : 8} lg={props.home ? 12 : 6}>
            <FighterCard fighter={fighter[11]['@ref'].id} 
            icon={sports.find((sport) => sport[0] === fighter[3]) ? sports.find((sport) => sport[0] === fighter[3])[1] : 'H'} />
           </Grid>
          ))}
         </Grid.Container>
         {filteredfighters.length < 1 && <p className="centered">There are no athletes matching your search</p>}
         </>}
         {props.home && <Button scale={.8} type="secondary" className="view-all" icon={<ChevronRight />} onClick={() => navigate('/fighters/')} ghost>View All</Button>}
      </div>
  )
}

export default FightersPage